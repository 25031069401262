import axios from 'axios'
import { useSelector } from 'react-redux'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

let projectInfo
const updateProjectInfo = () => {
    projectInfo = store.getState().auth.user
}

const BaseService = axios.create({
    timeout: 600000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(config => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    
    const accessToken = persistData.auth.session.token
    
    // Callback fonksiyonu çağır
    updateProjectInfo()

    // Değişkeni kullan
    const newUrl = config.url.split("?");
    if (accessToken && projectInfo) {
        if(config.url !== 'https://labeldnn.neocortexs.com/servis' && newUrl[0] !== 'https://labeldnn.neocortexs.com/yenirapor'){
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
            config.headers['project_id'] = projectInfo.project_id
        }
    }
    return config
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => response,
    error => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService